import request from '@/utils/request'


// 查询用户访客列表
export function listVisit(query) {
  return request({
    url: '/user/user-visit/list',
    method: 'get',
    params: query
  })
}

// 查询用户访客分页
export function pageVisit(query) {
  return request({
    url: '/user/user-visit/page',
    method: 'get',
    params: query
  })
}

// 查询用户访客详细
export function getVisit(data) {
  return request({
    url: '/user/user-visit/detail',
    method: 'get',
    params: data
  })
}

// 新增用户访客
export function addVisit(data) {
  return request({
    url: '/user/user-visit/add',
    method: 'post',
    data: data
  })
}

// 修改用户访客
export function updateVisit(data) {
  return request({
    url: '/user/user-visit/edit',
    method: 'post',
    data: data
  })
}

// 删除用户访客
export function delVisit(data) {
  return request({
    url: '/user/user-visit/delete',
    method: 'post',
    data: data
  })
}
